<template>
  <div class="loader-container">
    <div class="loader"></div>
  </div>
</template> 

<script>
export default {
  name: "WeLoading",
};
</script>

<style lang="scss" scoped>
.loader-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loader,
.loader:before,
.loader:after {
  background: #ffb300;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 44px;
  border-radius: 6px;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader {
  color: #ffb300;
  text-indent: -9999em;
  margin: auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 44px;
  }
  40% {
    box-shadow: 0 -2em;
    height: 54px;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 44px;
  }
  40% {
    box-shadow: 0 -2em;
    height: 54px;
  }
}
</style>